<template>
  <div>
    <div class="flex flex-col gap-2" v-if="crossSellings.length > 0">
      <div v-for="item in crossSellings" :key="item.id" class="flex flex-col justify-between p-2 rounded-lg border-2 border-primary">
        <p class="font-bold text-base">{{ item.namapaket }}</p>
        <div class="flex flex-row justify-between">
          <p class="font-bold text-base">Price</p>
          <p class="font-bold text-base">{{item.jml_dskn | toCurrency}}</p>
        </div>
        <div class="flex flex-row justify-between">
          <p class="font-bold text-base">Profit Share</p>
          <p class="font-bold text-base text-primary">{{item.nominal_crossselling | toCurrency}}</p>
        </div>
        <p class="font-semibold text-base">{{ item.waktucair_crosssell }}</p>
      </div>
    </div>
    <div class="grid text-center font-bold text-xl mt-4" v-else>
        No data found ...
    </div>
  </div>
</template>

<script>
export default {
  name: "CrossSellingRelease",

  data() {
    return {
      crossSellings: [],
    };
  },

  created() {
    this.$store.commit("setBreadcrumbItems", [
      { text: "Home", routeName: "Home" },
      { text: "Account", routeName: "AccountProfile" },
      { text: "Cross Selling", routeName: "CrossSelling" },
      { text: "Cross Selling Hold", routeName: "CrossSellingHold" },
    ]);

    this.getHoldCrossSelling();
  },

  methods: {
    async getHoldCrossSelling() {
      this.$store.commit("setOverlayLoading", true);

      try {
        const response = await this.$http.post(
          `${this.$apiSaeboTWV2}/ListOrderV3`,
          {
            id: "UATCROSSSELL",
            statussellcair: "1",
            kodecustomer: this.$store.state.user.id_user,
          }
        );

        if (response.data === "") {
          throw "History Cross Selling Empty";
        }

        this.crossSellings = response.data;
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    },
  },
};
</script>
